import { Badge, BadgeProps, Tooltip } from '@chakra-ui/react'

import { formatNumber } from '../../util/numbers'

import { CapitalizedSeverity } from './DefectsTagWithLabel'

export interface Props extends BadgeProps {
  severity: CapitalizedSeverity
  label?: string
  count: number
}

export const DefectsTag = ({ count, label, severity = 'High', onClick }: Props) => {
  const formattedLongCount = formatNumber(count, { shorten: false })
  const labelWithCount = label ? `${formattedLongCount} ${label}` : `${formattedLongCount}`

  return (
    <Tooltip label={labelWithCount}>
      <Badge variant={severity.toLowerCase()} minW="10" textAlign="right" {...(onClick ? { onClick, cursor: 'pointer' } : {})}>
        {formatNumber(count)}
      </Badge>
    </Tooltip>
  )
}
