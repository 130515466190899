import { useEffect } from 'react'

import { Stack } from '@chakra-ui/react'

import { getWorkspacePageTitle } from '../../titles'

import { GeneralCard } from './GeneralCard'

interface Props {
  allowWrite: boolean
  workspace: string
}

export function GeneralTab({ allowWrite, workspace }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'General')
  }, [workspace])

  return (
    <Stack spacing={4}>
      <GeneralCard workspace={workspace} allowWrite={allowWrite || false} />
    </Stack>
  )
}
