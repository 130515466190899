import { Link, useLocation } from 'wouter'
import { ChangeEvent, ReactNode } from 'react'
import { Button, Flex, HStack, Select, Text, useToken } from '@chakra-ui/react'

import { Pagination } from './Pagination/Pagination'

import { useQuery } from '@/hooks'
import { setPageQueryParam, setQueryParam, stringifyUrl } from '@/util/location'
import { cleanInteger } from '@/util/numbers'

import { DEFAULT_PER_PAGE_OPTIONS } from '@/redux/api/mayhemApi'

type Props = {
  /** Set to true to display the loading state. */
  isLoading: boolean
  /**
   * The query parameter prefix.  For example, set to `trip` if the query param
   * desired is `trippage`
   */
  prefix?: string
  /** The options available for page size. Defaults to [15,30,45] */
  pageSizeOptions?: number[]
  /**
   * The total number of records.
   * This is used to compute the number of pages available.
   */
  total: number
}

export function MayhemPagination({ isLoading, pageSizeOptions = DEFAULT_PER_PAGE_OPTIONS, total, prefix = '' }: Props) {
  const [location, setLocation] = useLocation()

  const queryParams = useQuery()

  const page = cleanInteger(queryParams.get(`${prefix}page`), 1)
  const pageSize = cleanInteger(queryParams.get(`${prefix}perPage`), pageSizeOptions[0])

  const paginationChangeHandler = (page: number): void => {
    if (page === 1) {
      queryParams.delete(`${prefix}page`)
      const newUrl = stringifyUrl(location, Object.fromEntries(queryParams))
      setLocation(newUrl)
    } else {
      const newUrl = setPageQueryParam(location, queryParams, page, prefix)
      setLocation(newUrl)
    }
  }

  const renderPageItem = (current: number, isDisabled: boolean, isLoading: boolean): ReactNode => {
    const href = setPageQueryParam(location, queryParams, page, prefix)
    return (
      <Button as={Link} size="md" variant="outline" colorScheme="gray" isDisabled={isDisabled} isLoading={isLoading} to={href}>
        {current}
      </Button>
    )
  }

  const pageSizeChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const newUrl = setQueryParam({ location, queryParams, param: `${prefix}perPage`, value: e.target.value.toString() })
    setLocation(newUrl)
  }

  // only show the pagination controls if there's more than 1 of the minimum size page
  const showPaginationControls = total > pageSizeOptions[0]

  const [paddingX, paddingY] = useToken('spacing', ['padding.X.card', 'padding.Y.card'])

  if (!showPaginationControls) {
    return <></>
  }

  return (
    <Flex
      justifyContent="center"
      paddingX={paddingX}
      paddingY={paddingY}
      borderColor="inherit"
      borderTopStyle="solid"
      borderTopWidth="0.5px"
      width="full"
    >
      <HStack align="center" gap={8}>
        <Pagination
          current={page}
          total={total}
          isLoading={isLoading}
          onChange={paginationChangeHandler}
          renderPageItem={renderPageItem}
          pageSize={pageSize}
        />
        <HStack gap={2}>
          <Text>Results per page:</Text>
          <Select value={pageSize} onChange={pageSizeChangeHandler} width="auto">
            {pageSizeOptions.map((pageSizeOption) => (
              <option key={`page-size-option-${pageSizeOption}`} value={pageSizeOption}>
                {pageSizeOption}
              </option>
            ))}
          </Select>
        </HStack>
      </HStack>
    </Flex>
  )
}
