import { HStack, useToken } from '@chakra-ui/react'

import { SearchBar } from './SearchBar'

interface Props {
  children: React.ReactNode
  placeholder: string
}

export default function FiltersWithSearch({ children, placeholder }: Props) {
  const [filtersGap] = useToken('spacing', ['gap.table.filters'])

  return (
    <HStack flexWrap="wrap" gap={filtersGap} width="100%">
      {children}
      <SearchBar placeholder={placeholder} InputGroupProps={{ flex: '1 1' }} />
    </HStack>
  )
}
