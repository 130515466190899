import { Tab, TabList, TabPanel, TabPanels, Tabs, TabsProps } from '@chakra-ui/react'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useRoute } from 'wouter'

export type DataTab = {
  label: string
  content: ReactNode
  route: string
}

interface Props extends Omit<TabsProps, 'children'> {
  tabs: DataTab[]
}

// component to wrap around Tab so we can use useRoute() for determining the selected tab
function SelectableTab({
  tab,
  index,
  onSelectedChange
}: {
  tab: DataTab
  index: number
  onSelectedChange: (index: number, isSelected: boolean) => void
}) {
  const { label, route } = tab

  const [match] = useRoute(route)
  useEffect(() => {
    onSelectedChange(index, !!match)
  }, [match, onSelectedChange, index])

  return <Tab>{label}</Tab>
}

export function DataTabs({ tabs, ...rest }: Props) {
  const [_location, setLocation] = useLocation()
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChanged = (index: number) => {
    const tab = tabs.find((tab, idx) => idx === index)
    if (tab) {
      setLocation(tab.route, { replace: true })
    }
  }

  const handleSelectedChange = (index: number, isSelected: boolean) => {
    if (isSelected) {
      setSelectedTab(index)
    }
  }

  return (
    <Tabs index={selectedTab} onChange={handleTabChanged} {...rest}>
      <TabList>
        {tabs.map((tab, index) => (
          <SelectableTab key={index} tab={tab} index={index} onSelectedChange={handleSelectedChange} />
        ))}
      </TabList>
      <TabPanels paddingTop={4}>
        {tabs.map((tab, index) => (
          <TabPanel paddingX={0} key={`tab_panel_${index}`}>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}
