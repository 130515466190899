import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['event'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getEvents: build.query<GetEventsApiResponse, GetEventsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/event`,
          params: { event_phase: queryArg.eventPhase, min_event_level: queryArg.minEventLevel, offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['event']
      }),
      postEvent: build.mutation<PostEventApiResponse, PostEventApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/event`,
          method: 'POST',
          body: queryArg.postEvent
        }),
        invalidatesTags: ['event']
      }),
      getEventsAsCsv: build.query<GetEventsAsCsvApiResponse, GetEventsAsCsvApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/run/${queryArg.runNumber}/events.csv`
        }),
        providesTags: ['event']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetEventsApiResponse = /** status 200 Returns list of events for a given run, optional,
        filter by phase_name

    Returns:
        list(events) -- list of events objects from a target.
        count(int) -- total number of event objects from a target.
     */ EventList
export type GetEventsApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  eventPhase?: string
  minEventLevel?: number
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type PostEventApiResponse = /** status 200 Reports a new error for a run phase

    Returns:
        Event -- The Event object that was created.
     */ EventBase
export type PostEventApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  postEvent: PostEvent
}
export type GetEventsAsCsvApiResponse = /** status 200 A csv stream of all the event logs */ Blob
export type GetEventsAsCsvApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The number of the run for the specified project / target. */
  runNumber: number
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type EventBase = {
  created_at?: string
  updated_at?: string
  event_id?: number
  run_id?: number
  event_level: number
  event_code: number
  event_phase: string
  component: string
  event_desc?: string
  event_attributes?: {
    [key: string]: any
  } | null
  suggestion?: boolean
}
export type EventList = {
  events?: EventBase[]
  count: number
}
export type ApiError = {
  message?: string
}
export type PostEvent = {
  event_level: number
  event_code: number
  event_phase: string
  component: string
  event_desc?: string
  event_attributes?: {
    [key: string]: any
  } | null
  suggestion?: boolean
}
