import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react'

import OutsideLink from '../../components/OutsideLink'
import DsbomWhyImageLight from '../../images/dsbom-reduction.svg'
import DsbomWhyImageDark from '../../images/dsbom-reduction-darkmode.svg'

export function DsbomComparisonWhy() {
  const PromoImage = useColorModeValue(DsbomWhyImageLight, DsbomWhyImageDark)
  return (
    <Card width="full" maxWidth="1280px">
      <Stack spacing={4} alignItems="center">
        <Accordion allowToggle width="full">
          <AccordionItem border="none">
            <AccordionButton justifyContent="center" borderRadius="2xl" _expanded={{ borderRadius: '1rem 1rem 0 0' }}>
              <Heading size="md" textAlign="center" margin={4}>
                Why Mayhem Dynamic SBOM?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Flex direction={{ base: 'column', xl: 'row' }} alignItems="top" gap={16} paddingX={16}>
                <Image src={PromoImage} alt="Mayhem Dynamic SBOM" height={80} justifySelf="center" />
                <Stack gap={4} justifySelf="left">
                  <Text>Use Mayhem to filter down SCA and SBOM results, so you can focus on what&apos;s actionable.</Text>
                  <Text>
                    Mayhem Dynamic SBOM (DSBOM) augments SBOM and SCA tooling with dynamic results obtained from observing actual behavior of
                    containerized applications.
                  </Text>
                  <Text>
                    By collecting and indexing data about which components are used at runtime vs merely statically present in an OCI image, we can
                    provide greater insight into the impact of reported vulnerabilities than possible through static SCA alone.
                  </Text>
                  <Text>
                    For more information on MDSBOM or the installation process, please visit our{' '}
                    <OutsideLink href="https://docs.mayhem.security/dynamic-sbom/summary/">docs</OutsideLink>.
                  </Text>
                </Stack>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
    </Card>
  )
}
