import { extendTheme, withDefaultColorScheme, theme as baseTheme } from '@chakra-ui/react'

import { colors } from './colors'
import { Autocomplete } from './components/autocomplete'
import { Badge } from './components/badge'
import { Button } from './components/button'
import { CardRow } from './components/cardRow'
import { Card } from './components/card'
import { CodeBlock } from './components/codeBlock'
import { FuzzingLoader } from './components/fuzzingLoader'
import { semanticTokens } from './semanticTokens'
import { Heading } from './components/heading'
import { Menu } from './components/menu'
import { ProgressIndicator } from './components/progressIndicator'
import { Search } from './components/search'
import { Stat } from './components/stat'
import { Alert } from './components/alert'
import { Table } from './components/table'
import { Tabs } from './components/tabs'
import { fonts } from './fonts'
import { Link } from './components/link'
import { styles } from './styles'
import { DefectTrendChart } from './components/defectTrendChart'
import { Modal } from './components/modal'
import { SearchResultRow } from './components/searchResultRow'
import { Chart } from './components/chart'
import { Stepper } from './components/stepper'
import { Form } from './components/form'
import { Popover } from './components/popover'
import { Input } from './components/input'

const components = {
  Alert,
  Autocomplete,
  Badge,
  Button,
  Card,
  CardRow,
  Chart,
  CodeBlock,
  DefectTrendChart,
  Form,
  FuzzingLoader,
  Heading,
  Link,
  Menu,
  Popover,
  ProgressIndicator,
  Search,
  Stat,
  Table,
  Tabs,
  Modal,
  SearchResultRow,
  Stepper,
  Input
}

// adds 3xl and 4xl sizes for big screens
const breakpoints = {
  base: '0em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
  '3xl': '120em',
  '4xl': '144em'
}

// adds 4xl size
const radii = {
  none: '0',
  sm: '0.125rem',
  base: '0.25rem',
  md: '0.375rem',
  lg: '0.5rem',
  xl: '0.75rem',
  '2xl': '1rem',
  '3xl': '1.5rem',
  '4xl': '1.75rem',
  full: '9999px'
}

export const theme = extendTheme(
  {
    semanticTokens,
    components,
    colors,
    fonts,
    styles,
    breakpoints,
    radii,
    initialColorMode: 'light',
    useSystemColorMode: true
  },
  withDefaultColorScheme({
    colorScheme: 'brand',
    components: Object.keys(baseTheme.components || {}).filter((name) => name !== 'Link')
  })
)
