import { useMemo } from 'react'

import { Button, Card, CardHeader, Grid, GridItem, Heading, useDisclosure } from '@chakra-ui/react'

import { WebhookList } from '@/features/admin-webhooks/WebhookList'
import { CreateWebhookModal } from '@/features/admin-webhooks/CreateWebhookModal'

type Props = {
  workspace: string
}

export function WebhooksCard({ workspace }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const subscription = useMemo(() => {
    return { owner: workspace }
  }, [workspace])

  return (
    <Card>
      <CardHeader>
        <Grid width="full" templateColumns="1fr" alignItems="baseline">
          <GridItem gridRow={1} gridColumn={1} textAlign="center">
            <Heading variant="cardHeading">Webhooks</Heading>
          </GridItem>
          <GridItem gridRow={1} gridColumn={1} justifySelf="end">
            <Button onClick={onOpen}>Create Webhook</Button>
          </GridItem>
        </Grid>
      </CardHeader>
      <WebhookList workspace={workspace} />
      <CreateWebhookModal isOpen={isOpen} onClose={onClose} subscription={subscription} />
    </Card>
  )
}
