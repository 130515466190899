import { ReactNode, useEffect, useRef, useState, useCallback } from 'react'
import { Box, Container, Heading, Stack } from '@chakra-ui/react'
import { useLocation } from 'wouter'

import { Page } from '../../components/Page'

import { setQueryParam } from '@/util/location'
import { useQuery } from '@/hooks'

interface Section {
  id: string
  component: ReactNode
}

interface SettingsPageProps {
  title: string
  sections: Section[]
}

export function ScrollableSettingsPage({ title, sections }: SettingsPageProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null)
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([])
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()
  const [currentSection, setCurrentSection] = useState<string | null>(null)

  // update the currently active section based on the scroll position
  const updateCurrentSection = useCallback(() => {
    if (!containerRef.current) {
      return
    }

    const containerTop = containerRef.current.offsetTop
    const scrollPosition = window.scrollY + containerTop + 250

    let newCurrentSection: string | null = null

    sectionRefs.current.forEach((sectionRef, index) => {
      if (sectionRef && sectionRef.offsetTop <= scrollPosition) {
        newCurrentSection = sections[index].id
      }
    })

    if (newCurrentSection && newCurrentSection !== currentSection) {
      setCurrentSection(newCurrentSection)
      setLocation(setQueryParam({ location, queryParams, param: 'scroll', value: newCurrentSection }), { replace: true })
    }
  }, [sections, currentSection, setLocation, location, queryParams])

  useEffect(() => {
    const handleScroll = () => {
      window.requestAnimationFrame(updateCurrentSection)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [updateCurrentSection])

  useEffect(() => {
    updateCurrentSection()
  }, [updateCurrentSection])

  return (
    <Page>
      <Container maxW="container.lg">
        <Stack spacing={8} marginBottom="40rem" ref={containerRef}>
          <Heading textAlign="center">{title}</Heading>
          {sections.map((section, index) => (
            <Box ref={(el) => (sectionRefs.current[index] = el)} id={section.id} key={section.id} paddingBottom={16} scrollMarginTop={8}>
              {section.component}
            </Box>
          ))}
        </Stack>
      </Container>
    </Page>
  )
}
