import { Stack } from '@chakra-ui/react'

import { Page } from '../../components/Page'
import { useGetRunQuery } from '../../redux/api/runs'
import { useInterval } from '../../hooks'

import { RunBreadcrumb } from '../run/RunBreadcrumb'
import { RunHeader } from '../run/RunHeader'

import { RunCodePageTabs } from './RunCodePageTabs'
import { RunCodePageStats } from './RunCodePageStats'

import RunCodePageGraph from './RunCodePageGraph'

import { RunNextStepsCard } from './RunNextStepsCard'

export interface RunCodePageProps {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
}

export function RunCodePage({ owner, projectSlug, targetSlug, runNumber }: RunCodePageProps) {
  const { isLoading, data: run, refetch } = useGetRunQuery({ owner, projectSlug, targetSlug, runNumber })

  // Only auto refresh a run that has not ended.
  useInterval({
    action: () => {
      if (!isLoading && !run?.ended) {
        refetch()
      }
    },
    interval: 3000,
    deps: [owner, projectSlug, targetSlug, runNumber, run?.ended]
  })

  const { failed } = run || {}

  return (
    <Page isLoading={isLoading} header={<RunBreadcrumb workspace={owner} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />}>
      <Stack gap={4}>
        <RunHeader owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />
        {failed && <RunNextStepsCard owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />}
        <RunCodePageStats workspace={owner} project={projectSlug} target={targetSlug} runNumber={runNumber} />
        <RunCodePageGraph owner={owner} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />
        <RunCodePageTabs workspace={owner} project={projectSlug} target={targetSlug} runNumber={runNumber} />
      </Stack>
    </Page>
  )
}
