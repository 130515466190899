import { useCallback } from 'react'

import { Button, Card, CardBody, CardHeader, Heading, Stack, useDisclosure, useToast } from '@chakra-ui/react'
import { useLocation } from 'wouter'

import TargetDeleteModal from './TargetDeleteModal'

import { getCurrentUserInfo } from '@/features/auth/utils'

import { getMessageFromRtkError } from '@/util/errors'

import { useAppSelector } from '@/hooks'

import { useDeleteTargetMutation } from '@/redux/api/targets'

import { useGetProjectMemberPermissionQuery } from '@/redux/api/projects'

import MayhemTableLoader from '@/components/MayhemTableLoader'

type Props = {
  workspace: string
  project: string
  target: string
}

export function TargetSettingsGeneralPage({ workspace, project, target }: Props) {
  const toast = useToast()
  const [_location, setLocation] = useLocation()

  const { userSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { isLoading: projectMemberPermissionIsLoading, data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner: workspace,
    projectSlug: project,
    userSlug
  })

  const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure()
  const [deleteTarget] = useDeleteTargetMutation()
  const onDeleteTarget = useCallback(async () => {
    try {
      toast({
        title: 'Target Deleted',
        description: `${target} was deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      setLocation(`/${workspace}`)
      await deleteTarget({ owner: workspace, projectSlug: project, targetSlug: target }).unwrap()
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      toast({
        title: 'Delete Target Failed',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }, [toast, deleteTarget, workspace, project, target, setLocation])

  if (projectMemberPermissionIsLoading) {
    return <MayhemTableLoader />
  }

  const isAtLeastWritePermission = isMayhemAdmin || (projectPermission && projectPermission.permission !== 'READ')

  return (
    <>
      {isAtLeastWritePermission && (
        <Stack spacing={8}>
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Danger Zone</Heading>
            </CardHeader>
            <CardBody p={8}>
              <Button colorScheme="red" onClick={onDeleteConfirmationOpen}>
                Delete Target
              </Button>
            </CardBody>
          </Card>
          {isDeleteConfirmationOpen && workspace && project && target && (
            <TargetDeleteModal
              owner={workspace}
              projectSlug={project}
              targetName={target}
              deleteHandler={onDeleteTarget}
              closeHandler={onDeleteConfirmationClose}
              open={isDeleteConfirmationOpen}
            />
          )}
        </Stack>
      )}
    </>
  )
}
