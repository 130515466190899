import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'

import { TSFixMe } from '../../types'
import { respace } from '../../util/respace'

import { ProgressIndicatorVariants } from '../../components/ProgressIndicator/ProgressIndicator'

type RunPhase = {
  description: string
  name: string
  value: string | null
}

type RunState = {
  description: string
  name: ProgressIndicatorVariants
  phase: RunPhase
}

export function getRunStateFromStatusBools({
  stopped,
  failed,
  ended,
  started,
  pending,
  isDeleted
}: {
  stopped: boolean
  failed: boolean
  ended: boolean
  started: boolean
  pending: boolean
  isDeleted: boolean
}): RunState {
  let name: ProgressIndicatorVariants
  let description

  if (isDeleted) {
    description = 'Scheduled for Deletion'
    name = 'deleting'
  } else if (failed) {
    description = 'Failed'
    name = 'failed'
  } else if (stopped) {
    description = 'Stopped'
    name = 'stopped'
  } else if (ended) {
    description = 'Completed'
    name = 'ended'
  } else if (started) {
    description = 'Running'
    name = 'running'
  } else if (pending) {
    description = 'Pending'
    name = 'pending'
  } else {
    description = 'Running'
    name = 'running'
  }

  const result: RunState = {
    name,
    description,
    phase: { name: '', description: '', value: null }
  }

  return result
}

export const RunListTypes = {
  ALL: 'all' as RunListType,
  MY: 'my' as RunListType,
  ACTIVE: 'active' as RunListType,
  PENDING: 'pending' as RunListType
}

export type RunListType = 'all' | 'my' | 'active' | 'pending'

export function getPhaseCode(phase: string): string {
  switch (phase) {
    case 'static_analysis':
      return 'EF'
    case 'regression_testing':
      return 'RT'
    case 'dynamic_analysis':
      return 'BT'
    case 'post_processing':
      return 'PP'
    case 'coverage_analysis':
      return 'CA'
    default:
      return ''
  }
}

export const RunTaskFilters = {
  ACTIVE: 'active',
  PENDING: 'pending',
  COMPLETED: 'completed'
}

export const RunAnalysisFilters = {
  ALL: 'all',
  REGRESSION: 'regression',
  BEHAVIOR: 'behavior'
}

export const RunResultsFilters = {
  DEFECTS: 'defects',
  TESTCASES: 'testcases'
}
export type TestcaseFilter = 'new' | 'fixed' | 'regression' | 'fail' | 'pass'

export const RunTestCaseFilters = {
  NEW: 'new_test' as TestcaseFilter,
  REGRESSION_FIXED: 'regression_test_fixed' as TestcaseFilter,
  REGRESSION_FAILED: 'regression_test_regressed' as TestcaseFilter,
  REGRESSION_STILL_FAILING: 'regression_test_still_failing' as TestcaseFilter,
  REGRESSION_STILL_PASSING: 'regression_test_still_passing' as TestcaseFilter
}

export type TestcaseStatusFilter = 'all' | 'fail' | 'pass'

export const RunTestCaseStatusFilters = {
  FAIL: 'failing' as TestcaseStatusFilter,
  PASS: 'passing' as TestcaseStatusFilter
}

export const getPhaseFilter = (analysisFilter: string) => {
  let phaseFilter = undefined
  if (analysisFilter === RunAnalysisFilters.REGRESSION) {
    phaseFilter = 'regression_testing'
  } else if (analysisFilter === RunAnalysisFilters.BEHAVIOR) {
    phaseFilter = 'dynamic_analysis'
  }
  return phaseFilter
}

export const getTaskNameFromApiData = (dataName: string): string => {
  switch (dataName) {
    case 'static_analysis':
      return 'Exploitability Factors'

    case 'dynamic_analysis':
      return 'Behavior Testing'

    default:
      return respace(dataName)
  }
}

/**
 * Parsing for the overrides used for a Mayhem run, to handle possibly out of date terminology.
 *
 * To start out, this only replaces "tests" with "testsuite" (does this for both the key and value)
 *
 * @param {Record<string, unknown>} overrides - the overrides object used for a run
 * @returns {Record<string, unknown>} the overrides with keys renamed as needed
 */
export function parseRunOverrides(overrides: Record<string, unknown>): Record<string, unknown> {
  let ret = mapKeys(overrides, (_value, key) => {
    switch (key) {
      case 'tests':
        return 'testsuite'
      default:
        return key
    }
  })

  ret = mapValues(ret, (value, key) => {
    switch (key) {
      case 'testsuite':
        // swap tests.tgz/tests.tar with testsuite.tgz/testsuite.tar
        return (value as Array<string>).map((val) => val.replace('tests.t', 'testsuite.t'))

      default:
        return value
    }
  })

  return ret
}

/**
 * Returns a display-friendly string version of the specified override.
 * Includes special handling for the tasks override, which is currently (as of 2.0)
 * an array of objects.
 * @param overrideKey the key of the override (e.g. 'duration')
 * @param override the value of the override (e.g. 300)
 * @returns a display-friendly version of the override value
 */
export function getOverrideDisplay(overrideKey: string, override: TSFixMe): string {
  let overrideDisplay = override

  // for task overrides, only show the name of the task
  if (overrideKey === 'tasks' && Array.isArray(overrideDisplay) && typeof overrideDisplay[0] === 'object') {
    overrideDisplay = overrideDisplay.map((task) => task.name)
  }

  if (Array.isArray(overrideDisplay)) {
    overrideDisplay = overrideDisplay.join(', ')
  }

  return overrideDisplay
}

export const MAYHEMFILE_DESCRIPTION_RUN =
  'This is the fully populated version of the Mayhemfile used for this run, complete with default values and any environment variables/overrides used to start the run.'
export const MAYHEMFILE_DESCRIPTION_SOURCE =
  'This is the Mayhemfile you used to start this run/should be the version you have stored in source control.'

/**
 * Returns the number to display for the Regression Testing stat for Code runs.
 * This number should be the 0-100 (decimal) percent of passing regression tests.
 *
 * @returns the primary regression testing stat to display for a Code run
 */
export function getCodeRegressionDisplayStat(nRegressionTestcaseReports: number, nRegressionDefectReports: number): number {
  return nRegressionTestcaseReports ? ((nRegressionTestcaseReports - nRegressionDefectReports) / nRegressionTestcaseReports) * 100.0 : 0
}

/**
 * Returns the number to display for the Test Suite stat for Code runs.
 * This number is the optimized test suite size (taken from the run itself, which was added in 2.10, or using the
 * value in run_attributes as a fallback).
 *
 * @returns the primary test suite stat to display for a Code run
 */
export function getCodeTestsuiteDisplayStat(nOptimizedSetTestcases: number, nOptimizedTestsuiteSizeFromRunAttribute: number): number {
  return nOptimizedSetTestcases > 0 ? nOptimizedSetTestcases : nOptimizedTestsuiteSizeFromRunAttribute
}

/**
 * Returns the number to display for the Coverage stat for Code runs.
 * This number should be the line coverage percent, but if that is not available then use
 * the block coverage percent as a fallback.
 *
 * @returns the primary coverage stat to display for a Code run
 */
export function getCodeCoverageDisplayStat(nLinesCovered: number, nLinesTotal: number, nBlocksCovered: number, nBlocksTotal: number): number {
  const blockCoveragePercentDisplay = nBlocksTotal ? (nBlocksCovered / nBlocksTotal) * 100.0 : 0
  const lineCoveragePercentDisplay = nLinesTotal ? (nLinesCovered / nLinesTotal) * 100.0 : 0
  return nLinesCovered ? lineCoveragePercentDisplay : blockCoveragePercentDisplay
}
