import { useEffect } from 'react'
import { Link as WouterLink } from 'wouter'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Card, CardHeader, Heading, useDisclosure } from '@chakra-ui/react'

import { CreateWebhookModal } from '@/features/admin-webhooks/CreateWebhookModal'
import { WebhookList } from '@/features/admin-webhooks/WebhookList'
import { getProjectsSettingsPageTitle } from '@/titles'
import { getCurrentUserInfo } from '@/features/auth/utils'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { setWebhook } from '@/features/admin-webhooks/webhooks.slice'
import { useGetProjectMemberPermissionQuery } from '@/redux/api/projects'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'

type Props = {
  workspace: string
  project: string
}

export function ProjectSettingsWebhooksPage({ workspace: owner, project: projectSlug }: Props) {
  const dispatch = useAppDispatch()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { userSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner,
    projectSlug,
    userSlug
  })

  useEffect(() => {
    document.title = getProjectsSettingsPageTitle(owner, projectSlug, 'Webhooks')
  })

  useEffect(() => {
    dispatch(setWebhook({ subscription: { owner } }))
  }, [dispatch, owner])

  const isAdminPermission = isMayhemAdmin || (projectPermission && projectPermission.permission === 'ADMIN')

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${owner}/${projectSlug}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={projectSlug} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Webhooks</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Heading mb={8}>Webhooks</Heading>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Webhooks</Heading>
          {isAdminPermission && <Button onClick={onOpen}>Create New Webhook</Button>}
        </CardHeader>
        <WebhookList workspace={owner} projectSlug={projectSlug} />
      </Card>
      <CreateWebhookModal isOpen={isOpen} onClose={onClose} subscription={{ project_slug: projectSlug, owner }} />
    </Page>
  )
}
