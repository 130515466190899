import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'

import { useEffect } from 'react'

import { Page } from '@/components/Page'
import { useAppDispatch } from '@/hooks'

import { CreateOrganizationForm } from '@/features/organization/CreateOrganizationForm'

import { getCreateWorkspacePageTitle } from '@/titles'

export const CreateWorkspacePage = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    document.title = getCreateWorkspacePageTitle()
  }, [dispatch])

  return (
    <Page>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Create a new Workspace</Heading>
        </CardHeader>
        <CardBody p={8}>
          <CreateOrganizationForm />
        </CardBody>
      </Card>
    </Page>
  )
}
