import { Badge, Box, HStack, Icon, Tooltip } from '@chakra-ui/react'
import EastIcon from '@material-design-icons/svg/sharp/east.svg?react'

import PassFailCheck, { Result } from '../../components/PassFailCheck'

export default function RunTestCaseResult({
  text,
  isRegression,
  previousHasDefects,
  hasDefects
}: {
  text?: string
  isRegression?: boolean
  previousHasDefects?: boolean
  hasDefects: boolean
}): JSX.Element {
  let resultText = text

  if (!resultText) {
    if (isRegression) {
      if (hasDefects) {
        if (previousHasDefects) {
          resultText = 'Defects Still Present'
        } else if (previousHasDefects !== null) {
          resultText = 'Regression'
        } else {
          resultText = 'Defects Found'
        }
      } else if (previousHasDefects) {
        resultText = 'Defects Fixed'
      } else {
        resultText = 'No Defects Found'
      }
    } else if (hasDefects) {
      resultText = 'Defects Found'
    } else {
      resultText = 'No Defects Found'
    }
  }

  if (!isRegression) {
    return (
      <Badge variant="subtle" colorScheme="brand">
        New Test
      </Badge>
    )
  }

  return (
    <HStack spacing={2} alignItems="center" lineHeight={3}>
      <Tooltip
        label={`${
          previousHasDefects ? 'Defects' : previousHasDefects !== null ? 'No defects' : 'No results'
        } found in previous run for this test case.`}
      >
        <Box>
          <PassFailCheck result={previousHasDefects ? Result.FAIL : previousHasDefects !== null ? Result.PASS : Result.NONE} />
        </Box>
      </Tooltip>

      <Icon as={EastIcon} boxSize={4} />

      <Tooltip label={`${hasDefects ? 'D' : 'No d'}efects found in this run for this test case.`}>
        <Box>
          <PassFailCheck result={hasDefects ? Result.FAIL : hasDefects !== null ? Result.PASS : Result.NONE} />
        </Box>
      </Tooltip>
    </HStack>
  )
}
