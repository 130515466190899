import { Avatar, Box, Center, HStack, SkeletonCircle } from '@chakra-ui/react'

import { useWhoamiQuery } from '../../redux/api/workspace'

import { UserProfileButton } from './UserProfileButton'

interface UserProfileWidgetProps {
  containerRef: React.RefObject<HTMLDivElement>
}

export const UserProfileWidget = (props: UserProfileWidgetProps) => {
  const { isLoading, data: profile } = useWhoamiQuery()
  if (isLoading) {
    return (
      <Center>
        <SkeletonCircle height={14} width={14} />
      </Center>
    )
  }
  const fullname = [profile?.first_name, profile?.last_name].join(' ').trim()

  return (
    <UserProfileButton containerRef={props.containerRef} variant="ghost" justifyContent="start" height="flex" paddingX={2} paddingY={2}>
      <HStack spacing="3" alignItems="center">
        <Box display="flex">
          <Avatar name={fullname || profile?.username} src={profile?.avatar ?? undefined} bg="gray.200" color="blackAlpha.800" size="sm" />
          {profile?.is_admin && (
            <Box position="absolute" left="30px">
              👑
            </Box>
          )}
        </Box>
        {/* <Flex alignItems="flex-start" justifyContent="center" flexDirection="column">
          {fullname && (
            <Text textAlign="left" fontWeight="bold" fontSize="lg" noOfLines={2} whiteSpace="normal" wordBreak="break-word">
              {fullname}
            </Text>
          )}
          <Text textAlign="left" fontSize="md" noOfLines={1} whiteSpace="normal" overflowWrap="anywhere">
            {profile?.username}
          </Text>
        </Flex> */}
      </HStack>
    </UserProfileButton>
  )
}
