import { Flex, HStack, Icon, Spacer } from '@chakra-ui/react'

import { useRef } from 'react'

import { ToggleButton } from './ToggleButton'

import MayhemWordmark from '@/images/mayhem-wordmark.svg?react'
import { useAppSelector } from '@/hooks'
import { NavbarSearch } from '@/features/workspace/NavbarSearch'
import { UserProfileWidget } from '@/features/workspace-sidebar/UserProfileWidget'

// the bar that appears on mobile to open the sidenav
export const MobileNavbar = ({ isOpen, onToggle }: MobileNavbarProps) => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <Flex width="full" padding={4} gridArea="mobile-navbar" background="sidebarBg" borderBottomWidth={1} height={16} align="center">
      {isAuthenticated && <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />}
      <Spacer />
      <Icon as={MayhemWordmark} boxSize={44} height={16} fill="primaryText" paddingX={2} />
      <Spacer />
      {isAuthenticated && (
        <HStack ref={containerRef} justifyContent="flex-end" gap={0}>
          <NavbarSearch />
          <UserProfileWidget containerRef={containerRef} />
        </HStack>
      )}
    </Flex>
  )
}

export interface MobileNavbarProps {
  isOpen: boolean
  onToggle: () => void
}
