import { HStack } from '@chakra-ui/react'

import { DefectsTagWithLabel } from './DefectsTagWithLabel'

import { DefectsTag } from './DefectsTag'

import { useUpdateQueryParams } from '@/util/location'

import { SeverityCategory, categoryFromScore } from '@/features/run-api-defects/runApiDefects.slice'

export interface RunDefectsSeverityBreakdownProps {
  high: number
  medium: number
  low: number
}

export const RunDefectsSeverityBreakdown = ({ high, medium, low }: RunDefectsSeverityBreakdownProps) => {
  const updateQueryParams = useUpdateQueryParams()

  return (
    <HStack spacing={2}>
      <DefectsTag severity="High" count={high} onClick={() => updateQueryParams({ severity: 'high' })} />
      <DefectsTag severity="Medium" count={medium} onClick={() => updateQueryParams({ severity: 'medium' })} />
      <DefectsTag severity="Low" count={low} onClick={() => updateQueryParams({ severity: 'low' })} />
      <DefectsTagWithLabel severity="Total" count={high + medium + low} onClick={() => updateQueryParams({ severity: undefined })} />
    </HStack>
  )
}

export const LabeledDefectTagNum = ({ severityNumber }: { severityNumber: number }) => {
  switch (categoryFromScore(severityNumber)) {
    case SeverityCategory.High:
      return <DefectsTagWithLabel severity="High" count={severityNumber} />
    case SeverityCategory.Medium:
      return <DefectsTagWithLabel severity="Medium" count={severityNumber} />
  }

  return <DefectsTagWithLabel severity="Low" count={severityNumber} />
}
