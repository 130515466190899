import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Box, Button, Card, CardBody, CardHeader, FormControl, FormErrorMessage, FormLabel, Heading, Stack, useToast } from '@chakra-ui/react'

import { usePostTeamMemberMutation } from '@/redux/api/teams'
import { SearchResult, SelectableGlobalSearchField } from '@/features/search/SelectableGlobalSearchField'
import { getMessageFromRtkError } from '@/util/errors'

type Props = {
  workspace: string
  team: string
}

type Form = {
  user: SearchResult
}

const validateUserSelection = (user: SearchResult | undefined) => (!user || !user.owner ? 'You must select a user.' : undefined)

export function AddMembersCard({ workspace, team }: Props) {
  const toast = useToast()
  const [postTeamMember] = usePostTeamMemberMutation()
  const methods = useForm<Form>()
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isDirty, isValid, isSubmitting }
  } = methods

  const onSubmit = async ({ user }: Form) => {
    try {
      await postTeamMember({ owner: workspace, teamSlug: team, newTeamMember: { slug: user.owner } })
      toast({
        title: 'Team Member Added',
        description: `${user.owner} was added to ${team}`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      setError('user', { type: 'custom', message })
    }
  }

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Add Member</Heading>
      </CardHeader>
      <CardBody padding={4}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormControl isInvalid={!!errors.user}>
                <FormLabel>Select User</FormLabel>
                <Controller
                  control={control}
                  rules={{
                    required: 'This is required',
                    maxLength: { value: 128, message: 'Maximum Length is 128' },
                    validate: (v) => validateUserSelection(v)
                  }}
                  name="user"
                  render={({ field: { onChange, onBlur, ref } }) => (
                    <SelectableGlobalSearchField
                      includeUsers
                      placeholder="Search for a user"
                      isDisabled={isSubmitting}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      workspace={workspace}
                    />
                  )}
                ></Controller>
                <FormErrorMessage>{errors?.user?.message}</FormErrorMessage>
              </FormControl>
              <Box>
                <Button type="submit" variant="solid" disabled={!isValid || !isDirty} isLoading={isSubmitting}>
                  Add Member
                </Button>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </CardBody>
    </Card>
  )
}
