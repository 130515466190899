import { As, Button, ButtonProps, HStack, Icon, Text, Tooltip, useColorModeValue } from '@chakra-ui/react'

export interface NavButtonProps extends ButtonProps {
  icon?: As
  label: string
  isActive?: boolean
  isCollapsed?: boolean
  variant?: string
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, isActive, isCollapsed = false, variant, ...buttonProps } = props

  const tooltipBg = useColorModeValue('blue.900', 'gray.50')
  const tooltipColor = useColorModeValue('white', 'gray.900')

  return (
    <Tooltip
      hasArrow
      bg={tooltipBg}
      color={tooltipColor}
      padding={2}
      borderRadius={8}
      label={label}
      placement="bottom-start"
      isDisabled={!isCollapsed}
    >
      <Button
        variant={variant || 'sidenav-link'}
        justifyContent="start"
        width={!isCollapsed ? 'full' : 'auto'}
        aria-current={isActive ? 'page' : undefined}
        isActive={isActive}
        paddingX={!isCollapsed ? 3 : 2}
        {...buttonProps}
      >
        <HStack spacing="3">
          {icon && <Icon as={icon} boxSize="6" color={isActive ? 'selectedColor' : 'primaryText'} />}
          {!isCollapsed && <Text>{label}</Text>}
        </HStack>
      </Button>
    </Tooltip>
  )
}
