import { useToken } from '@chakra-ui/react'

import { TableCard } from '@/components/Card/TableCard'

interface Props {
  children: React.ReactNode
  actions: React.ReactNode
}

export function TargetsTableCard({ actions, children }: Props) {
  const [paddingY] = useToken('spacing', ['padding.Y.card'])

  return (
    <TableCard actions={actions} title="Targets" CardBodyProps={{ paddingTop: paddingY }}>
      {children}
    </TableCard>
  )
}
