import { useEffect } from 'react'

import { getRunPageTitle } from '../../titles'
import { RunApiPage } from '../run-api/RunApiPage'
import { useGetTargetQuery } from '../../redux/api/targets'
import { RunCodePage } from '../run-code/RunCodePage'
import { useGetRunQuery } from '../../redux/api/runs'
import { ErrorPage } from '../../components/layout/ErrorPage'

interface Props {
  workspace: string
  project: string
  target: string
  runNumber: number
}

export function RunPage({ workspace, project: projectSlug, target: targetSlug, runNumber }: Props) {
  const { data: target, isError: isTargetError, error: targetError } = useGetTargetQuery({ owner: workspace, projectSlug, targetSlug })
  const { data: run, isError: isRunError, error: runError } = useGetRunQuery({ owner: workspace, projectSlug, targetSlug, runNumber })

  useEffect(() => {
    document.title = getRunPageTitle(workspace, projectSlug, targetSlug, runNumber.toString())
  }, [workspace, projectSlug, targetSlug, runNumber])

  if (isTargetError && targetError) {
    const goBackLocation = `/${workspace}`
    if (targetError && 'status' in targetError) {
      if (targetError.status === 404) {
        return <ErrorPage errorCode={404} errorMessage="We couldn't find the specified target" goBackLocation={goBackLocation} />
      } else {
        return <ErrorPage errorMessage="An error occurred while fetching the target" goBackLocation={goBackLocation} />
      }
    }
    return <ErrorPage errorMessage="An error occurred while fetching the target" goBackLocation={goBackLocation} />
  }

  if (isRunError && runError) {
    const goBackLocation = `/${workspace}`
    if (runError && 'status' in runError) {
      if (runError.status === 404) {
        return <ErrorPage errorCode={404} errorMessage="We couldn't find the specified run" goBackLocation={goBackLocation} />
      } else {
        return <ErrorPage errorMessage="An error occurred while fetching the run" goBackLocation={goBackLocation} />
      }
    }
    return <ErrorPage errorMessage="An error occurred while fetching the run" goBackLocation={goBackLocation} />
  }

  if (target && target.type === 'code') {
    return <RunCodePage owner={workspace} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />
  }

  if (target && run && target.type === 'api') {
    return <RunApiPage owner={workspace} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} runId={run.run_id} />
  }
}
