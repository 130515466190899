import { useRoute, Link as WouterLink } from 'wouter'
import { motion } from 'framer-motion'
import { Heading, Stack, Text, Icon, HStack } from '@chakra-ui/react'

import { MenuSection } from './WorkspaceSidebar'

import { getSelectedRoute } from './utils'

import SettingsIcon from '@/images/nav-settings.svg?react'

import { NavButton } from '@/components/Shell/NavButton'

import { useQuery } from '@/hooks'

export const SettingsMenu = ({ workspace, showWorkspaceSettings, showAdminSettings }: SettingsMenuProps) => {
  const adminSettingsPageActive = useRoute('/-/settings/admin/*?')[0]

  const queryParams = useQuery()
  const jumpToSection = queryParams.get('jumpTo') || undefined
  const scrollSection = queryParams.get('scroll') || undefined

  const sections: MenuSection[] = [
    {
      name: 'Account Settings',
      routes: [
        { path: '/-/settings/user?jumpTo=user-personal', scrollTo: 'user-personal', name: 'Your Details', key: 'user-settings' },
        { path: '/-/settings/user?jumpTo=user-workspaces', scrollTo: 'user-workspaces', name: 'Workspaces', key: 'user-workspaces' },
        { path: '/-/settings/user?jumpTo=user-api-tokens', scrollTo: 'user-api-tokens', name: 'API Tokens', key: 'user-api-tokens' }
      ],
      isShown: true
    },
    {
      name: 'Workspace Settings',
      routes: [
        { path: `/${workspace}/-/settings?jumpTo=general`, scrollTo: 'general', name: 'General', key: 'workspace-settings' },
        { path: `/${workspace}/-/settings?jumpTo=users`, scrollTo: 'users', name: 'Users', key: 'workspace-users' },
        { path: `/${workspace}/-/settings?jumpTo=teams`, scrollTo: 'teams', name: 'Teams', key: 'workspace-teams' }
      ],
      isShown: showWorkspaceSettings
    },
    {
      name: 'Integrations',
      routes: [
        { path: `/${workspace}/-/integrations?jumpTo=languages`, scrollTo: 'languages', name: 'Languages', key: 'integrations-languages' },
        { path: `/${workspace}/-/integrations?jumpTo=apis`, scrollTo: 'apis', name: 'APIs', key: 'integrations-apis' },
        { path: `/${workspace}/-/integrations?jumpTo=cicd`, scrollTo: 'cicd', name: 'CI/CD', key: 'integrations-cicd' },
        { path: `/${workspace}/-/integrations?jumpTo=buildtools`, scrollTo: 'buildtools', name: 'Build Tools', key: 'integrations-buildtools' },
        {
          path: `/${workspace}/-/integrations?jumpTo=notifications`,
          scrollTo: 'notifications',
          name: 'Notifications',
          key: 'integrations-notifications'
        },
        { path: `/${workspace}/-/integrations?jumpTo=webhooks`, scrollTo: 'webhooks', name: 'Webhooks', key: 'integrations-webhooks' }
      ],
      isShown: true
    },
    {
      name: 'Plan',
      routes: [{ path: `/${workspace}/-/plan`, name: 'Plan Details', key: 'workspace-plan' }],
      isShown: showWorkspaceSettings
    }
  ]

  const selectedRoute = getSelectedRoute(sections, jumpToSection, scrollSection)

  return (
    <motion.div key="subnav-settings" variants={undefined} initial="initial" animate="animate" exit="exit">
      <Stack spacing="1" maxHeight="100vh" overflowY="auto">
        <HStack>
          <Icon as={SettingsIcon} boxSize={6} />
          <Heading size="md" paddingY={4}>
            All Settings
          </Heading>
        </HStack>
        {sections.map(
          (section) =>
            section.isShown && (
              <>
                <Text color="gray.900" as="b" paddingY={4}>
                  {section.name}
                </Text>
                {section.routes.map((route) => {
                  return (
                    <WouterLink key={route.key} to={route.path}>
                      <NavButton label={route.name} isActive={selectedRoute?.key === route.key} />
                    </WouterLink>
                  )
                })}
              </>
            )
        )}
        {showAdminSettings && (
          <WouterLink to="/-/settings/admin">
            <NavButton label="Admin Settings" variant="secondary" paddingX={5} marginTop={4} marginBottom={8} isActive={adminSettingsPageActive} />
          </WouterLink>
        )}
      </Stack>
    </motion.div>
  )
}

export interface SettingsMenuProps {
  workspace: string
  showWorkspaceSettings: boolean
  showAdminSettings: boolean
}
