import { Box, HStack, useColorMode, useToken } from '@chakra-ui/react'
import React from 'react'

import { formatNumber } from '@/util/numbers'

export type Severity = 'low' | 'medium' | 'high'
export type CapitalizedSeverity = 'High' | 'Medium' | 'Low'

export interface DefectsTagWithLabelProps {
  count: number
  severity: CapitalizedSeverity | 'Total'
  label?: string
  onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
}

const computeSeverityScheme = (severity: string) => {
  if (severity === 'Total') {
    return 'subtleBg'
  }

  return `severity${severity}`
}

export const DefectsTagWithLabel = ({ count, severity = 'High', label, onClick }: DefectsTagWithLabelProps) => {
  const [severityColor] = useToken('colors', [computeSeverityScheme(severity)])

  const { colorMode } = useColorMode()

  const isSeverityTotal = severity === 'Total'

  if (!label) {
    if (isSeverityTotal) {
      label = severity.toUpperCase()
    } else {
      label = severity.slice(0, 1)
    }
  }

  return (
    <HStack gap={0} fontSize={12} role={onClick && 'button'} aria-label={onClick && `Filter to ${severity}`} onClick={onClick} height={7}>
      <Box
        px={1.5}
        bg={severityColor}
        borderTopLeftRadius={8}
        borderBottomLeftRadius={8}
        fontWeight={600}
        {...(isSeverityTotal ? {} : { fontSize: 10, color: colorMode === 'light' ? 'white' : 'gray.900' })}
        lineHeight={7}
        textAlign="center"
      >
        {label}
      </Box>
      <Box
        width="fit-content"
        paddingX={1.5}
        bg="subtleBg"
        borderTopRightRadius={8}
        borderBottomRightRadius={8}
        textAlign="center"
        lineHeight={7}
        fontWeight={600}
      >
        {formatNumber(count)}
      </Box>
    </HStack>
  )
}
