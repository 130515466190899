import { Box, CircularProgressLabel } from '@chakra-ui/react'

import { PercentProgressStatusIndicator } from '@/components/PercentProgressStatusIndicator/PercentProgressStatusIndicator'

interface Props {
  numerator: number
  denominator: number
}

export function RunCodeTestIndicator({ numerator, denominator }: Props) {
  const percent = Math.round((numerator / denominator) * 100)

  if (!denominator) {
    return '-'
  }

  return (
    <PercentProgressStatusIndicator percent={percent} size={20}>
      <CircularProgressLabel fontSize={12} marginTop={-2}>
        {numerator}
      </CircularProgressLabel>
      <CircularProgressLabel>
        <Box border="1px solid gray" width={6} margin="0 auto" />
      </CircularProgressLabel>
      <CircularProgressLabel fontSize={12} marginTop={2}>
        {denominator}
      </CircularProgressLabel>
    </PercentProgressStatusIndicator>
  )
}
