import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { modalAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(modalAnatomy.keys)

const modalVariants = {
  base: {
    dialog: {
      background: 'body'
    }
  },
  search: definePartsStyle({
    dialog: {
      borderRadius: 'md'
    },
    body: {
      padding: '3',
      overflowX: 'hidden',
      justifyContent: 'center',
      alignItems: 'center'
    },
    header: {
      padding: '2',
      marginBottom: '2'
    },
    footer: {
      padding: 2,
      display: 'flex',
      flexDirection: 'row',
      gap: '2'
    }
  })
}

export const Modal = defineMultiStyleConfig({
  variants: modalVariants,
  defaultProps: { variant: 'base' }
})
