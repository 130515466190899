import { CardBodyProps, useToken } from '@chakra-ui/react'

import { TableCard } from '@/components/Card/TableCard'

interface Props {
  children: React.ReactNode
  actions: React.ReactNode
  CardBodyProps?: CardBodyProps
}

export function RunsTableCard({ actions, children, CardBodyProps }: Props) {
  const [paddingY] = useToken('spacing', ['padding.Y.card'])

  return (
    <TableCard actions={actions} title="Runs" CardBodyProps={{ ...CardBodyProps, paddingTop: paddingY }}>
      {children}
    </TableCard>
  )
}
