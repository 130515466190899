import { Container, Stack } from '@chakra-ui/react'

import { RunApiStats } from './RunApiStats'

import { Page } from '@/components/Page'

import { RunApiDefects } from '@/features/run-api-defects/RunApiDefects'
import { EndpointList } from '@/features/run-api-endpoints/EndpointList'
import { EndpointsViz } from '@/features/run-api-endpoints/EndpointsViz'
import { ApiRunLog } from '@/features/run-api-log/ApiRunLog'

import { DataTab, DataTabs } from '@/components/DataTabs'
import { RunBreadcrumb } from '@/features/run/RunBreadcrumb'

import { RunHeader } from '@/features/run/RunHeader'

import { Requests } from '@/features/requests/Requests'

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  runId: number
}

export function RunApiPage(props: Props) {
  const { owner, projectSlug, targetSlug, runNumber } = props

  const tabData: DataTab[] = [
    {
      label: 'Defects',
      content: <RunApiDefects {...props} />,
      route: `/${owner}/${projectSlug}/${targetSlug}/${runNumber}`
    },
    {
      label: 'Endpoints',
      content: <EndpointList {...props} />,
      route: `/${owner}/${projectSlug}/${targetSlug}/${runNumber}/endpoints`
    },
    {
      label: 'Sample Requests',
      content: <Requests {...props} />,
      route: `/${owner}/${projectSlug}/${targetSlug}/${runNumber}/tests`
    },
    {
      label: 'Log',
      content: <ApiRunLog {...props} />,
      route: `/${owner}/${projectSlug}/${targetSlug}/${runNumber}/log`
    }
  ]

  return (
    <Page header={<RunBreadcrumb workspace={owner} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />}>
      <Stack gap={4}>
        <RunHeader {...props} />
        <RunApiStats {...props} />
        <EndpointsViz {...props} />
        <Container width="full" maxW="full" padding={0}>
          <DataTabs variant="line" isLazy width="full" tabs={tabData} />
        </Container>
      </Stack>
    </Page>
  )
}
