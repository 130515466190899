import { Button, useDisclosure, useToast } from '@chakra-ui/react'

import { useCallback } from 'react'
import { useLocation } from 'wouter'

import WorkspaceDeleteModal from './WorkspaceDeleteModal'

import { useDeleteOrgMutation } from '@/redux/api/workspace'

import { getMessageFromRtkError } from '@/util/errors'

type Props = {
  owner: string
  workspace: string
}

export function WorkspaceDeleteButton({ owner, workspace }: Props) {
  const [_location, setLocation] = useLocation()
  const toast = useToast()
  const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure()
  const [deleteWorkspace] = useDeleteOrgMutation()
  const onDeleteWorkspace = useCallback(async () => {
    try {
      toast({
        title: 'Workspace Deleted',
        description: `${workspace} was deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      setLocation(`/${owner}`) // Personal workspace cannot be deleted - safe to go here
      await deleteWorkspace({ orgSlug: workspace }).unwrap()
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      toast({
        title: 'Delete Workspace Failed',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }, [toast, deleteWorkspace, owner, workspace, setLocation])

  return (
    <>
      <Button colorScheme="red" variant="ghost" onClick={onDeleteConfirmationOpen}>
        Delete Workspace
      </Button>
      {isDeleteConfirmationOpen && owner && workspace && (
        <WorkspaceDeleteModal
          workspace={workspace}
          deleteHandler={onDeleteWorkspace}
          closeHandler={onDeleteConfirmationClose}
          open={isDeleteConfirmationOpen}
        />
      )}
    </>
  )
}
