import { Badge, Text } from '@chakra-ui/react'

import TextWithTooltip from '../../components/TextWithTooltip'

export function ResponseStatusBadge({ response_status }: { response_status?: number }) {
  let colorScheme = 'bad'
  if (response_status) {
    if (200 <= response_status && response_status < 400) {
      colorScheme = 'success'
    } else if (400 <= response_status && response_status < 500) {
      colorScheme = 'medium'
    }
  }

  return (
    <Badge variant={colorScheme} minW="10" textAlign="center">
      {response_status || (
        <TextWithTooltip tooltip={<Text>No response was received from the service under test.</Text>} text="∅" showQuestionMark={false} />
      )}
    </Badge>
  )
}
