import { Button, Td, Tr, useToast } from '@chakra-ui/react'

import { useDeleteApiTokenMutation } from '@/redux/api/apiTokens'

import { prettyFullTimeString } from '@/util/time'
interface Props {
  owner: string
  token: string
  name?: string
  lastAccess?: string
}

export function ApiToken({ token, owner, name, lastAccess }: Props) {
  const [deleteTokenTrigger, { isLoading }] = useDeleteApiTokenMutation()
  const toast = useToast()

  const revoke = () => {
    deleteTokenTrigger({ userSlug: owner, token: token })
      .unwrap()
      .then((_) => {
        toast({
          title: 'API token deleted',
          description: "We've deleted the requested api token.",
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      })
      .catch((_) => {
        toast({
          title: 'API token deletion error',
          description: 'There was an error deleting this api token.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      })
  }

  return (
    <Tr>
      <Td>{name}</Td>
      <Td>{lastAccess ? prettyFullTimeString(lastAccess) : 'Never Accessed'}</Td>
      <Td textAlign="center">
        <Button colorScheme="red" variant="outline" onClick={revoke} isLoading={isLoading}>
          Revoke
        </Button>
      </Td>
    </Tr>
  )
}
